import { FC } from 'react';
import IconProps from './IconProps';

const BuilderIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7794 15.3006L19.7794 21.3006L21.8894 19.1406L15.8894 13.1406L13.7794 15.3006ZM17.4994 10.1006C17.1094 10.1006 16.6894 10.0506 16.3594 9.91062L4.96937 21.2506L2.85938 19.1406L10.2694 11.7406L8.49938 9.96063L7.77938 10.6606L6.32938 9.25063V12.1106L5.62938 12.8106L2.10938 9.25063L2.80938 8.55063H5.61937L4.21937 7.14062L7.77938 3.58062C8.94938 2.41062 10.8294 2.41062 11.9994 3.58062L9.88938 5.74062L11.2994 7.14062L10.5894 7.85063L12.3794 9.63063L14.1994 7.75063C14.0594 7.42063 13.9994 7.00063 13.9994 6.63063C13.9994 4.66063 15.5594 3.11062 17.4994 3.11062C18.0894 3.11062 18.6094 3.25063 19.0794 3.53062L16.4094 6.20062L17.9094 7.70062L20.5794 5.03063C20.8594 5.50063 20.9994 6.00063 20.9994 6.63063C20.9994 8.55063 19.4494 10.1006 17.4994 10.1006Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BuilderIcon;
